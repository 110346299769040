import { Cookies } from 'react-cookie';
import { DRAWER_PREFERENCES } from '../../constants/stored';
import { DrawerPreferences } from '../../models/dtos/preferences/drawer-preferences.dto';

export const storeDrawerPreferencesInCookie = (
	dto: DrawerPreferences
): void => {
	const cookies = new Cookies();
	cookies.set(DRAWER_PREFERENCES, dto, {
		path: '/',
		sameSite: true,
		secure: true,
		maxAge: 2628288, // 1 month
	});
};

export const getDrawerPreferencesCookie = (): DrawerPreferences | undefined => {
	const cookies = new Cookies();

	return cookies.get(DRAWER_PREFERENCES);
};
