import React, { ReactElement, SyntheticEvent } from 'react';
import {
	useTheme,
	Typography,
	Snackbar,
	Alert,
	AlertColor,
} from '@mui/material';
import materialSnackBarStyle from '../../styles/material.snackBar.style';
import { SnackbarLevelsEnum } from '../../../models/enums/snackbar-levels.enum';

type CustomProps = {
	label: string;
	open: boolean;
	onCloseCallback: any;
	level: SnackbarLevelsEnum;
	origin: any;
};

export default function ThemedSnackbar(props: CustomProps): ReactElement {
	const theme = useTheme();

	let severity: AlertColor | undefined;
	switch (props.level) {
		case SnackbarLevelsEnum.ERROR:
			severity = 'error';
			break;
		case SnackbarLevelsEnum.SUCCESS:
			severity = 'success';
			break;
		default:
			severity = 'info';
	}

	return (
		<Snackbar
			anchorOrigin={props.origin}
			sx={materialSnackBarStyle({
				theme,
				level: props.level,
			})}
			open={props.open}
			autoHideDuration={6000}
			onClose={(event: SyntheticEvent | Event, reason?: string): void => {
				if (reason === 'clickaway') {
					return;
				}
				props.onCloseCallback();
			}}
		>
			<Alert
				onClose={props.onCloseCallback}
				severity={severity}
				elevation={6}
				variant='filled'
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					'& .MuiAlert-action': {
						padding: '0 0 0 16px',
					},
				}}
			>
				<Typography variant='body2'>{props.label}</Typography>
			</Alert>
		</Snackbar>
	);
}
