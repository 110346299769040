import React, {
	ReactElement,
	Reducer,
	useReducer,
	useEffect,
	useRef,
} from 'react';
import {
	Card,
	CardContent,
	CardActionArea,
	Typography,
	CircularProgress,
	Box,
} from '@mui/material';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { checkSessionLogIn } from '../../services/login.service';
import { UserEntity } from '../../models/entities/user/user.entity';
import { checkAutoLogInProtectedPageRouter } from '../../utils/authentication';
import * as MuiIcons from '@mui/icons-material';
import {
	USER_ICON,
	DANGER_ICON,
	TOWN_PARTNER_ICON,
} from '../../constants/material-icons';
import { translations } from '../../utils/translation';
import {
	DANGERS_ROUTE,
	TOWNS_PARTNERS_ROUTE,
	USERS_ROUTE,
} from '../../constants/routes';

type CustomState = {
	errorMessage: string;
	displayErrorMessage: boolean;
	authChecked: boolean;
	authUser: UserEntity | undefined;
};

export default function RootScreen(): ReactElement {
	const initialized = useRef(false);
	const location = useLocation();
	const navigate = useNavigate();
	const userAuth = checkSessionLogIn();

	const [state, setState] = useReducer<
		Reducer<CustomState, Partial<CustomState>>
	>((oldState, newState) => ({ ...oldState, ...newState }), {
		errorMessage: '',
		displayErrorMessage: false,
		authChecked: userAuth.data !== undefined,
		authUser: userAuth.data,
	});

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;
			(async (): Promise<void> => {
				await checkAutoLogInProtectedPageRouter(location, navigate, setState);
			})();
		}
	});

	if (!state.authChecked) {
		return (
			<Box className='page-fill flex-center'>
				<CircularProgress />
			</Box>
		);
	}

	// @ts-ignore
	const UserIcon: any = MuiIcons[USER_ICON];
	const DangerIcon: any = MuiIcons[DANGER_ICON];
	const TownIcon: any = MuiIcons[TOWN_PARTNER_ICON];

	return (
		<Box className='page-fill background-gradient-accents flex-center'>
			<Card sx={{ margin: '10px' }}>
				<CardActionArea>
					<Link
						className='color-classic-text'
						to={USERS_ROUTE}
						state={{
							from: location.pathname,
						}}
					>
						<CardContent sx={{ '&&:last-child': { paddingBottom: '16px' } }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<UserIcon sx={{ fontSize: 50, margin: '10px' }} />
								<Typography variant='h1'>{translations.users}</Typography>
							</Box>
						</CardContent>
					</Link>
				</CardActionArea>
			</Card>
			<Card sx={{ margin: '10px' }}>
				<CardActionArea>
					<Link
						className='color-classic-text'
						to={DANGERS_ROUTE}
						state={{
							from: location.pathname,
						}}
					>
						<CardContent sx={{ '&&:last-child': { paddingBottom: '16px' } }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<DangerIcon sx={{ fontSize: 50, margin: '10px' }} />
								<Typography variant='h1'>{translations.dangers}</Typography>
							</Box>
						</CardContent>
					</Link>
				</CardActionArea>
			</Card>
			<Card sx={{ margin: '10px' }}>
				<CardActionArea>
					<Link
						className='color-classic-text'
						to={TOWNS_PARTNERS_ROUTE}
						state={{
							from: location.pathname,
						}}
					>
						<CardContent sx={{ '&&:last-child': { paddingBottom: '16px' } }}>
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<TownIcon sx={{ fontSize: 50, margin: '10px' }} />
								<Typography variant='h1'>
									{translations.towns_partners}
								</Typography>
							</Box>
						</CardContent>
					</Link>
				</CardActionArea>
			</Card>
		</Box>
	);
}
