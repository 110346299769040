import { Cookies } from 'react-cookie';
import { UserCredentialsEntity } from '../../models/entities/user/user-credentials.entity';
import { USER_CREDENTIALS } from '../../constants/stored';
import { UserEntity } from '../../models/entities/user/user.entity';

export const storeUserCredentialsInCookie = (
	dto: UserCredentialsEntity
): void => {
	const cookies = new Cookies();
	cookies.set(USER_CREDENTIALS, dto, {
		path: '/',
		sameSite: true,
		secure: true,
		maxAge: 2628288, // 1 month
	});
};

export const getUserCredentialsCookie = ():
	| UserCredentialsEntity
	| undefined => {
	const cookies = new Cookies();

	return cookies.get(USER_CREDENTIALS);
};

export const deleteUserCredentialsCookie = (): void => {
	const cookies = new Cookies();
	cookies.remove(USER_CREDENTIALS, { path: '/' });
};

export const storeUserCredentialsSession = (dto: UserEntity): void => {
	sessionStorage.setItem(USER_CREDENTIALS, JSON.stringify(dto));
};

export const deleteUserCredentialSession = (): void => {
	sessionStorage.removeItem(USER_CREDENTIALS);
};

export const getUserCredentialsSession = (): UserEntity | undefined => {
	const data = sessionStorage.getItem(USER_CREDENTIALS);
	if (data === null || data === undefined) {
		return undefined;
	}

	return JSON.parse(data);
};
