import React, { ReactElement, ChangeEvent } from 'react';
import { useTheme, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import materialCheckBoxStyle from '../../styles/material.checkBox.style';

type CustomProps = {
	onChangeCallback: any;
	label: string;
	checked: boolean;
	disabled?: boolean;
};

export default function ThemedCheckBox(props: CustomProps): ReactElement {
	const theme = useTheme();

	return (
		<FormGroup
			sx={materialCheckBoxStyle({
				theme,
			})}
		>
			<FormControlLabel
				control={
					<Checkbox
						disabled={props.disabled}
						checked={props.checked}
						onChange={(event: ChangeEvent<HTMLInputElement>): void => {
							props.onChangeCallback(event.target.checked);
						}}
					/>
				}
				label={props.label}
			/>
		</FormGroup>
	);
}
