import { CSSObject, Theme } from '@mui/material';
import { getModeFromTheme } from '../../utils/theme';
import { MAIN_BACKGROUND_COLOR, Colors } from '../../constants/colors';

type CustomProps = {
	theme: Theme;
};

export default function materialSelectMenuItemStyle(
	props: CustomProps
): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'&&': {
			backgroundColor: colors[MAIN_BACKGROUND_COLOR],
			'&:hover': {
				backgroundColor: 'rgba(255,255,255,0.15)',
			},
		},
		'&.Mui-selected': {
			backgroundColor: 'rgba(255,255,255,0.10)',
		},
	};
}
