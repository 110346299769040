import { CSSObject, Theme } from '@mui/material';
import { Colors, MAIN_BACKGROUND_COLOR } from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
};

export default function materialSelectMenuStyle(props: CustomProps): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'& .MuiPaper-root': {
			'& .MuiList-root': {
				backgroundColor: colors[MAIN_BACKGROUND_COLOR],
			},
		},
	};
}
