import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/300.css';
import './view/styles/main.css';
import './view/styles/light.css';
import './view/styles/dark.css';
import App from './App';

createRoot(document.getElementById('root') as HTMLElement).render(
	<StrictMode>
		<App />
	</StrictMode>
);
