import React, { ReactElement, useState } from 'react';
import ThemedCheckBox from './form/ThemedCheckBox';
import LoadingButton from '@mui/lab/LoadingButton';
import materialDialogStyle from '../styles/material.dialog.style';
import * as MuiIcons from '@mui/icons-material';
import materialButtonStyle from '../styles/material.button.style';
import { ACCENT_PRIMARY_COLOR } from '../../constants/colors';
import {
	FormControl,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	useTheme,
	Typography,
} from '@mui/material';

type CustomProps = {
	showDialog: boolean;
	onCancel: any;
	onAgree: any;
	title?: string;
	description: string;
	checkBoxDescription: string;
	noText: string;
	yesText: string;
	yesButtonIcon: string;
	yesButtonLoading: boolean;
};

export default function ThemedAlertDialogCheckbox(
	props: CustomProps
): ReactElement {
	const theme = useTheme();

	const [checked, setChecked] = useState(false);

	// @ts-ignore
	const Icon: any = MuiIcons[props.yesButtonIcon];

	return (
		<Dialog
			sx={materialDialogStyle({
				theme,
			})}
			open={props.showDialog}
			onClose={props.onCancel}
		>
			<DialogTitle className='color-accent'>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.description}</DialogContentText>
				<FormControl fullWidth={true}>
					<ThemedCheckBox
						disabled={props.yesButtonLoading}
						checked={checked}
						label={props.checkBoxDescription}
						onChangeCallback={setChecked}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button disabled={props.yesButtonLoading} onClick={props.onCancel}>
					{props.noText}
				</Button>
				<LoadingButton
					sx={materialButtonStyle({
						theme,
						backgroundColor: ACCENT_PRIMARY_COLOR,
					})}
					onClick={(): void => props.onAgree(checked)}
					loadingPosition='start'
					variant='contained'
					startIcon={<Icon />}
					loading={props.yesButtonLoading}
				>
					<Typography variant='button'>{props.yesText}</Typography>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
