import { CSSObject, Theme } from '@mui/material';
import { ALERT_TEXT_COLOR, Colors } from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';
import { SnackbarLevelsEnum } from '../../models/enums/snackbar-levels.enum';

type CustomProps = {
	theme: Theme;
	level: SnackbarLevelsEnum;
};

export default function materialSnackBarStyle(props: CustomProps): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'& .MuiAlert-filled': {
			backgroundImage: 'none',
			color: colors[ALERT_TEXT_COLOR],
		},
	};
}
