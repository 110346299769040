import React, { ReactElement } from 'react';
import { Location, NavigateFunction, Navigate } from 'react-router-dom';
import { checkAutoLogIn } from '../services/login.service';
import { UserEntity } from '../models/entities/user/user.entity';
import { HOME_ROUTE, LOGIN_ROUTE } from '../constants/routes';

/**
 * Check if a user is stored in the session or cookies
 * if it's the case, navigate away from the current route
 * @param location
 * @param navigate
 * @param setState
 * @returns {boolean} true if navigate, else false
 */
export const checkAutoLogInAuthPageRouter = async (
	location: Location,
	navigate: NavigateFunction,
	setState: any
): Promise<boolean> => {
	const userCheckResponse = await checkAutoLogIn();
	if (!userCheckResponse.isError && userCheckResponse.data) {
		if (location.state?.from) {
			navigate(location.state.from);
		} else {
			navigate(HOME_ROUTE);
		}

		return true;
	}

	setState({
		errorMessage: userCheckResponse.message,
		displayErrorMessage: userCheckResponse.isError,
		authChecked: true,
		authUser: undefined,
	});
	return false;
};

/**
 * Check if a user is stored in the session or cookies and connect it
 * if it fails or no user is logged, navigate to login screen
 * @param location
 * @param navigate
 * @param setState
 * @returns {boolean} true if navigate, else false
 */
export const checkAutoLogInProtectedPageRouter = async (
	location: Location,
	navigate: NavigateFunction,
	setState: any
): Promise<{
	authUser?: UserEntity;
	shouldStay: boolean;
}> => {
	const userCheckResponse = await checkAutoLogIn();
	if (!userCheckResponse.isError && userCheckResponse.data) {
		setState({
			authChecked: true,
			authUser: userCheckResponse.data,
		});

		return {
			authUser: userCheckResponse.data,
			shouldStay: true,
		};
	}

	if (location.state?.from) {
		navigate(location.state.from);
	} else {
		navigate(LOGIN_ROUTE);
	}

	return {
		authUser: undefined,
		shouldStay: false,
	};
};

type CustomState = {
	authChecked: boolean;
	authUser: UserEntity | undefined;
};
export const shouldNavigateRestrictAuth = (
	state: CustomState,
	location: Location
): ReactElement | undefined => {
	if (state.authChecked && state.authUser) {
		return (
			<Navigate
				to={location.state?.from ? location.state?.from : '/'}
				replace={true}
			/>
		);
	}

	return undefined;
};
