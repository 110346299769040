import { CSSObject } from '@mui/material';

export default function materialListItemIconStyle(): CSSObject {
	// @ts-ignore
	return {
		'&&': {
			minWidth: 0,
			justifyContent: 'center',
		},
	};
}
