import { PaletteOptions } from '@mui/material';
import { Colors } from '../../constants/colors';

export const palette: PaletteOptions = {
	primary: {
		main: Colors.dark.accentPrimary,
		light: '#fff',
		dark: '#fff',
	},
	error: {
		main: Colors.dark.error,
	},
	success: {
		main: Colors.dark.error,
	},
	info: {
		main: Colors.dark.mainBackground,
	},
	text: {
		primary: Colors.dark.classicText,
	},
	background: {
		default: Colors.dark.mainBackground,
	},
	action: {
		hover: 'rgb(255,255,255)',
	},
};
