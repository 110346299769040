import moment from 'moment';
import LocalizedStrings from 'react-localization';
import translationsData from '../language/translations.json';

export const LANGUAGE_FR = 'fr_FR';

export const translations = new LocalizedStrings(translationsData);

export const getDateTimeFormatFromIso = (date: string): string => {
	if (translations.locale === LANGUAGE_FR) {
		return moment.utc(date).local().format('DD/MM/YYYY [à] HH[h]mm');
	}
	return moment.utc(date).local().format('MM/DD/YYYY [at] HH:mm');
};
