import { CSSObject, Theme } from '@mui/material';
import { Colors } from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
};

export default function materialDataGridStyle(props: CustomProps): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'& .MuiDataGrid-row:hover': {
			backgroundColor: colors.rippleColor,
		},
		'& .MuiDataGrid-cell:focus': {
			outline: ' none',
		},
		'& .MuiDataGrid-row': {
			'&.Mui-selected': {
				backgroundColor: colors.rippleColor,
			},
			'&.Mui-selected:hover': {
				backgroundColor: colors.rippleColor,
			},
		},
	};
}
