import { ResponseErrorHandler } from '../models/dtos/response-error-handler';
import {
	BASE_URL,
	handleRequestResult,
	makePostRequest,
	USERS_V1_ROUTE,
} from '../utils/network';
import { LogInFacebookUserDto } from '../models/dtos/user/log-in-facebook-user.dto';
import { LogInGoogleUserDto } from '../models/dtos/user/log-in-google-user.dto';
import { LogInUserDto } from '../models/dtos/user/log-in-user.dto';
import { AutoLogInUserDto } from '../models/dtos/user/auto-log-in-user.dto';
import { ThirdPartyLogInTypesEnum } from '../models/enums/third-party-log-in-types.enum';
import { translations } from '../utils/translation';
import { UserCredentialsEntity } from '../models/entities/user/user-credentials.entity';
import {
	deleteUserCredentialsCookie,
	deleteUserCredentialSession,
	getUserCredentialsCookie,
	getUserCredentialsSession,
	storeUserCredentialsInCookie,
	storeUserCredentialsSession,
} from '../utils/storage/user-profile';
import { UserEntity } from '../models/entities/user/user.entity';
import { AutoLogInGoogleUserDto } from '../models/dtos/user/auto-log-in-google-user.dto';
import { googleLogout } from '@react-oauth/google';

export const logInCredentials = async (
	dto: LogInUserDto
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePostRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/log-in/credentials`,
			dto
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const logInFacebook = async (
	dto: LogInFacebookUserDto,
	isAutoLogIn: boolean
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePostRequest(
			isAutoLogIn
				? `${BASE_URL}${USERS_V1_ROUTE}/auto-log-in/facebook`
				: `${BASE_URL}${USERS_V1_ROUTE}/log-in/facebook`,
			dto
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const logInGoogle = async (
	dto: LogInGoogleUserDto
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePostRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/log-in/google/code`,
			dto
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const autoLogInGoogle = async (
	dto: AutoLogInGoogleUserDto
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePostRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/auto-log-in/google`,
			dto
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const autoLogInCredentials = async (
	dto: AutoLogInUserDto
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePostRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/auto-log-in/credentials`,
			dto
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const autoLogIn = async (
	dto: UserCredentialsEntity
): Promise<ResponseErrorHandler> => {
	try {
		if (dto.id && dto.password) {
			return await autoLogInCredentials({
				id: dto.id,
				password: dto.password,
			});
		} else if (dto.thirdPartyAuthority && dto.thirdPartyToken) {
			if (dto.thirdPartyAuthority === ThirdPartyLogInTypesEnum.FACEBOOK) {
				return await logInFacebook(
					{
						token: dto.thirdPartyToken,
					},
					true
				);
			} else if (dto.thirdPartyAuthority === ThirdPartyLogInTypesEnum.GOOGLE) {
				return await autoLogInGoogle({
					refreshToken: dto.thirdPartyToken,
				});
			}
		}
		return {
			message: translations.error_occurred,
			isError: true,
		};
	} catch (e: any) {
		return handleRequestResult(null);
	}
};

export const logOutUser = (logOutDto: {
	userCredentials?: UserCredentialsEntity;
	user?: UserEntity;
}): void => {
	deleteUserCredentialsCookie();
	deleteUserCredentialSession();
	if (
		(logOutDto.user &&
			logOutDto.user.thirdParty &&
			logOutDto.user.thirdParty.authority ===
				ThirdPartyLogInTypesEnum.GOOGLE) ||
		(logOutDto.userCredentials &&
			logOutDto.userCredentials.thirdPartyAuthority ===
				ThirdPartyLogInTypesEnum.GOOGLE)
	) {
		googleLogout();
	}

	// //await crashlytics().setUserId('');
};

export const checkAutoLogIn = async (): Promise<ResponseErrorHandler> => {
	const userLogged = getUserCredentialsSession();
	if (userLogged) {
		return {
			isError: false,
			data: userLogged,
			message: undefined,
		};
	}

	const userCredentials = getUserCredentialsCookie();
	if (userCredentials) {
		const response = await autoLogIn(userCredentials);
		if (response.isError) {
			if (
				response.errorKey === 'ACCOUNT_BANNED' ||
				response.errorKey === 'WRONG_LOG_INFORMATION'
			) {
				logOutUser({
					userCredentials,
				});
				return {
					isError: true,
					data: undefined,
					message: response.message,
				};
			}
		} else {
			const user: UserEntity = response.data;
			storeUserCredentialsSession(user);
			storeUserCredentialsInCookie({
				id: user.id,
				password: user.password,
				thirdPartyAuthority: user.thirdParty?.authority,
				thirdPartyToken:
					user.thirdParty?.authority === ThirdPartyLogInTypesEnum.GOOGLE
						? user.thirdParty?.refreshToken
						: user.thirdParty?.token,
			});
		}
		return response;
	}

	return {
		isError: false,
		data: undefined,
		message: undefined,
	};
};

export const checkSessionLogIn = (): ResponseErrorHandler => {
	const userLogged = getUserCredentialsSession();
	if (userLogged) {
		return {
			isError: false,
			data: userLogged,
		};
	}

	return {
		isError: false,
		data: undefined,
	};
};

export const isUserCredentialsCookie = (): boolean => {
	const userCredentials = getUserCredentialsCookie();

	return userCredentials !== undefined;
};
