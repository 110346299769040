import { CSSObject, Theme } from '@mui/material';
import { Colors } from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
};

export default function materialDataGridMenuStyle(
	props: CustomProps
): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'&.MuiDataGrid-menu .MuiPaper-root': {
			'& .MuiDataGrid-menuList': {
				'& .MuiMenuItem-gutters:hover': {
					backgroundColor: colors.hintColor,
				},
			},
		},
	};
}
