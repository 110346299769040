import { CSSObject } from '@mui/material';

type CustomProps = {
	open: boolean;
};

export default function materialListItemButtonStyle(
	props: CustomProps
): CSSObject {
	// @ts-ignore
	return {
		'&&': {
			minHeight: 48,
			justifyContent: props.open ? 'initial' : 'center',
			px: 2,
			'&:hover': {
				backgroundColor: 'rgba(255,255,255,0.15)',
			},
		},
		'&.Mui-selected': {
			backgroundColor: 'rgba(255,255,255,0.10)',
		},
	};
}
