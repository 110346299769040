import { CSSObject } from '@mui/material';

type CustomProps = {
	open: boolean;
	isOverlay: boolean;
};

export default function materialListItemTextStyle(
	props: CustomProps
): CSSObject {
	// @ts-ignore
	return {
		'&&': {
			transition: '0.2s',
			opacity: props.open || props.isOverlay ? 1 : 0,
			marginLeft: props.open || props.isOverlay ? '20px' : 0,
			color: '#fff',
		},
	};
}
