import {
	useTheme,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import React, { ReactElement } from 'react';
import materialDialogStyle from '../styles/material.dialog.style';
import ReactJson from 'react-json-view';
import { translations } from '../../utils/translation';

type CustomProps = {
	showDialog: boolean;
	onCancel: any;
	json: object;
};

export default function ThemedAlertDialogShowJson(
	props: CustomProps
): ReactElement {
	const theme = useTheme();

	return (
		<Dialog
			sx={materialDialogStyle({
				theme,
			})}
			open={props.showDialog}
			onClose={props.onCancel}
			fullWidth
			maxWidth={false}
		>
			<DialogContent>
				<ReactJson
					src={props.json}
					theme='solarized'
					displayDataTypes={false}
					collapseStringsAfterLength={false}
					quotesOnKeys={false}
					iconStyle='square'
					displayObjectSize={false}
					enableClipboard={false}
					groupArraysAfterLength={5}
					indentWidth={4}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel}>{translations.cancel}</Button>
			</DialogActions>
		</Dialog>
	);
}
