import React, { ReactElement, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import materialDialogStyle from '../styles/material.dialog.style';
import { Download as DownloadButton } from '@mui/icons-material';
import materialButtonStyle from '../styles/material.button.style';
import { ACCENT_PRIMARY_COLOR } from '../../constants/colors';
import { translations } from '../../utils/translation';
import ThemedTextField from '../components/form/ThemedTextField';
import ThemedSelect from '../components/form/ThemedSelect';
import { DangerCloseStatusEnum } from '../../models/enums/danger-close-status.enum';
import {
	FormControl,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	useTheme,
} from '@mui/material';
import ThemedDatePicker from './form/ThemedDatePicker';
import { DatePickerTimeSetEnum } from '../../models/enums/date-picker-time-set.enum';
import { Dayjs } from 'dayjs';

type CustomProps = {
	showDialog: boolean;
	onCancel: any;
	onExport: any;
	yesButtonLoading: boolean;
};

export default function ThemedAlertDialogExportDangers(
	props: CustomProps
): ReactElement {
	const theme = useTheme();

	const [postalCode, setPostalCode] = useState('');
	const [status, setStatus] = useState(DangerCloseStatusEnum.ALL);
	const [startDate, setStartDate] = useState<Dayjs | undefined>(undefined);
	const [endDate, setEndDate] = useState<Dayjs | undefined>(undefined);

	return (
		<Dialog
			sx={materialDialogStyle({
				theme,
			})}
			open={props.showDialog}
			onClose={props.onCancel}
		>
			<DialogTitle className='color-accent'>
				{translations.export_csv}
			</DialogTitle>
			<DialogContent>
				<FormControl fullWidth={true}>
					<ThemedTextField
						onChangeTextCallback={setPostalCode}
						label={translations.postal_code}
						type='text'
						value={postalCode}
						required={true}
					/>
					<ThemedSelect
						label={translations.status}
						value={status}
						required={true}
						data={[
							{
								label: translations.all,
								value: DangerCloseStatusEnum.ALL,
							},
							{
								label: translations.opened,
								value: DangerCloseStatusEnum.OPENED,
							},
							{
								label: translations.closed,
								value: DangerCloseStatusEnum.CLOSED,
							},
						]}
						onChangeCallback={setStatus}
					/>
					<ThemedDatePicker
						label={translations.start_date}
						value={startDate}
						onChangeCallback={setStartDate}
						time={DatePickerTimeSetEnum.START_DAY}
					/>
					<ThemedDatePicker
						label={translations.end_date}
						value={endDate}
						onChangeCallback={setEndDate}
						time={DatePickerTimeSetEnum.END_DAY}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel}>{translations.cancel}</Button>
				<LoadingButton
					sx={materialButtonStyle({
						theme,
						backgroundColor: ACCENT_PRIMARY_COLOR,
					})}
					onClick={async (): Promise<void> => {
						props.onExport({
							postalCode,
							status,
							startDate: startDate ? startDate.toISOString() : startDate,
							endDate: endDate ? endDate.toISOString() : endDate,
						});
					}}
					loadingPosition='start'
					variant='contained'
					startIcon={<DownloadButton />}
					loading={props.yesButtonLoading}
					disabled={
						postalCode.length === 0 ||
						(startDate !== undefined &&
							endDate !== undefined &&
							endDate.isBefore(startDate))
					}
				>
					<Typography variant='button'>{translations.export_csv}</Typography>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
