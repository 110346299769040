export const MAIL_ICON = 'Email';
export const USER_ICON = 'Person';
export const DANGER_ICON = 'Error';
export const TOWN_PARTNER_ICON = 'LocationCity';
export const FACEBOOK_ICON = 'Facebook';
export const GOOGLE_ICON = 'Google';
export const LOG_OUT_ICON = 'Logout';
export const DELETE_ICON = 'Delete';
export const BAN_ICON = 'Block';
export const UNBAN_ICON = 'CheckCircleOutline';
export const CLOSE_ICON = 'CheckCircle';
export const EYE_ICON = 'Visibility';
export const DOWNLOAD_ICON = 'Download';
