import materialSelectStyle from '../../styles/material.select.style';
import materialSelectMenuItemStyle from '../../styles/material.selectMenuItem.style';
import materialSelectMenuStyle from '../../styles/material.selectMenu.style';
import React, { ReactElement } from 'react';
import { SelectData } from '../../../models/entities/select-data';
import {
	Box,
	InputLabel,
	Select,
	SelectChangeEvent,
	MenuItem,
	useTheme,
} from '@mui/material';

type CustomProps = {
	value: string;
	label: string;
	required?: boolean;
	data: SelectData[];
	onChangeCallback: any;
};

export default function ThemedSelect(props: CustomProps): ReactElement {
	const theme = useTheme();

	return (
		<Box style={{ position: 'relative' }}>
			<Select
				labelId='demo-simple-select-label'
				MenuProps={{
					sx: materialSelectMenuStyle({ theme }),
				}}
				sx={materialSelectStyle({ theme })}
				variant='outlined'
				label={props.label}
				value={props.value}
				required={props.required}
				fullWidth
				onChange={(event: SelectChangeEvent): void => {
					props.onChangeCallback(event.target.value);
				}}
			>
				{props.data.map((data) => (
					<MenuItem
						sx={materialSelectMenuItemStyle({ theme })}
						key={data.value}
						value={data.value}
					>
						{data.label}
					</MenuItem>
				))}
			</Select>
			<InputLabel
				required={props.required}
				style={{ top: '4px' }}
				id='demo-simple-select-label'
			>
				{props.label}
			</InputLabel>
		</Box>
	);
}
