import React, { ReactElement, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	ListItemIcon,
	Divider,
	Box,
	IconButton,
	useTheme,
} from '@mui/material';
import * as MuiIcons from '@mui/icons-material';
import {
	DANGERS_ROUTE,
	LOGIN_ROUTE,
	TOWNS_PARTNERS_ROUTE,
	USERS_ROUTE,
} from '../../../constants/routes';
import {
	DANGER_ICON,
	LOG_OUT_ICON,
	TOWN_PARTNER_ICON,
	USER_ICON,
} from '../../../constants/material-icons';
import { translations } from '../../../utils/translation';
import { ChevronLeft } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import materialListItemButtonStyle from '../../styles/material.listItemButton.style';
import materialListItemIconStyle from '../../styles/material.listItemIcon.style';
import materialListItemTextStyle from '../../styles/material.listItemText.style';
import materialDrawerStyle from '../../styles/material.drawer.style';
import materialDrawerIconStyle from '../../styles/material.drawerIcon.style';
import { logOutUser } from '../../../services/login.service';
import {
	getDrawerPreferencesCookie,
	storeDrawerPreferencesInCookie,
} from '../../../utils/storage/drawer-preferences';
import { DRAWER_SIZE_CLOSE, DRAWER_SIZE_OPEN } from '../../../constants/layout';

// @ts-ignore
export default function DrawerComponent({ children, user }): ReactElement {
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const isLittleScreen = useMediaQuery({ query: '(max-width: 1224px)' });
	const preferences = getDrawerPreferencesCookie();
	const [open, setOpen] = useState(
		isLittleScreen ? false : preferences ? !preferences.isClosed : true
	);
	const drawerMargin = isLittleScreen
		? DRAWER_SIZE_CLOSE
		: open
		? DRAWER_SIZE_OPEN
		: DRAWER_SIZE_CLOSE;

	const element = document.getElementById('page-content');
	if (element) {
		element.style.width = `calc(100% - ${drawerMargin}px)`;
	}

	const handleDrawerClose = (): void => {
		const newValue = !open;
		setOpen(newValue);
		if (!isLittleScreen) {
			storeDrawerPreferencesInCookie({
				isClosed: newValue === false,
			});
		}
	};

	// @ts-ignore
	const LogOutIcon: any = MuiIcons[LOG_OUT_ICON];

	const handleLogOut = (): void => {
		logOutUser(user);
		navigate(LOGIN_ROUTE);
	};

	// @ts-ignore
	const DrawerContent = ({ isOverlay }): ReactElement => (
		<>
			<Box
				sx={{
					marginTop: '10px',
					marginBottom: '10px',
					display: 'flex',
					justifyContent: open || isOverlay ? 'flex-end' : 'center',
				}}
			>
				<IconButton onClick={handleDrawerClose}>
					{open || isOverlay ? <ChevronLeft /> : <MenuIcon />}
				</IconButton>
			</Box>
			<Divider />
			<List>
				{[
					{
						label: translations.users,
						url: USERS_ROUTE,
						icon: USER_ICON,
					},
					{
						label: translations.dangers,
						url: DANGERS_ROUTE,
						icon: DANGER_ICON,
					},
					{
						label: translations.towns_partners,
						url: TOWNS_PARTNERS_ROUTE,
						icon: TOWN_PARTNER_ICON,
					},
				].map((button) => {
					// @ts-ignore
					const Icon: any = MuiIcons[button.icon];

					return (
						<ListItem key={button.label} disablePadding>
							<ListItemButton
								sx={materialListItemButtonStyle({
									open,
								})}
								selected={location.pathname === button.url}
							>
								<Link
									style={{
										display: 'flex',
										alignItems: 'center',
										width: '100%',
									}}
									to={button.url}
									state={{
										from: location.pathname,
									}}
								>
									<ListItemIcon sx={materialListItemIconStyle()}>
										<Icon sx={materialDrawerIconStyle()} />
									</ListItemIcon>
									<ListItemText
										primary={button.label}
										sx={materialListItemTextStyle({
											open,
											isOverlay,
										})}
									/>
								</Link>
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>

			<List style={{ marginTop: `auto` }}>
				<ListItem disablePadding>
					<ListItemButton
						onClick={handleLogOut}
						sx={materialListItemButtonStyle({
							open,
						})}
					>
						<ListItemIcon sx={materialListItemIconStyle()}>
							<LogOutIcon sx={materialDrawerIconStyle()} />
						</ListItemIcon>
						<ListItemText
							primary={translations.log_out}
							sx={materialListItemTextStyle({
								open,
								isOverlay,
							})}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</>
	);

	const container = window.document.body;

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer
				container={container}
				variant='temporary'
				open={open && isLittleScreen}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: isLittleScreen ? 'block' : 'none',
					...materialDrawerStyle({
						theme,
						open: open && isLittleScreen,
						isOverlay: true,
					}),
				}}
			>
				<DrawerContent isOverlay={true} />
			</Drawer>
			<Drawer
				open={open && !isLittleScreen}
				sx={materialDrawerStyle({
					theme,
					open: open && !isLittleScreen,
				})}
				variant='permanent'
				anchor='left'
			>
				<DrawerContent isOverlay={false} />
			</Drawer>
			{children}
		</Box>
	);
}
