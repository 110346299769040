import { CSSObject, Theme } from '@mui/material';
import {
	ACCENT_PRIMARY_COLOR,
	CLASSIC_TEXT_COLOR,
	Colors,
	MAIN_BACKGROUND_COLOR,
} from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
};

export default function materialDatePickerPopperStyle(
	props: CustomProps
): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'& .MuiPaper-root': {
			background: colors[MAIN_BACKGROUND_COLOR],
			'& .MuiSvgIcon-root': {
				color: colors[CLASSIC_TEXT_COLOR],
			},
			'& .MuiPickersDay-root.Mui-selected': {
				backgroundColor: colors[ACCENT_PRIMARY_COLOR],
			},
			'& .MuiPickersDay-root:not(.Mui-selected)': {
				borderColor: colors[CLASSIC_TEXT_COLOR],
			},
			'& .MuiPickersYear-yearButton.Mui-selected': {
				backgroundColor: colors[ACCENT_PRIMARY_COLOR],
			},
		},
	};
}
