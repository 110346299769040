import React, {
	ReactElement,
	Reducer,
	useReducer,
	useEffect,
	useRef,
} from 'react';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import {
	FacebookLoginClient,
	LoginResponse,
} from '@greatsumini/react-facebook-login';
import ThemedTextField from '../components/form/ThemedTextField';
import { translations } from '../../utils/translation';
import ThemedIconTextButton from '../components/form/ThemedIconTextButton';
import {
	ACCENT_PRIMARY_COLOR,
	FACEBOOK_COLOR,
	GOOGLE_COLOR,
} from '../../constants/colors';
import {
	FACEBOOK_ICON,
	GOOGLE_ICON,
	MAIL_ICON,
} from '../../constants/material-icons';
import { isEmailValid, isPasswordValid } from '../../services/profile.service';
import { LogInUserDto } from '../../models/dtos/user/log-in-user.dto';
import { isRequestError } from '../../utils/network';
import {
	checkSessionLogIn,
	logInCredentials,
	logInFacebook,
	logInGoogle,
	isUserCredentialsCookie,
} from '../../services/login.service';
import { UserEntity } from '../../models/entities/user/user.entity';
import {
	storeUserCredentialsInCookie,
	storeUserCredentialsSession,
} from '../../utils/storage/user-profile';
import ThemedSnackbar from '../components/form/ThemedSnackbar';
import { SnackbarLevelsEnum } from '../../models/enums/snackbar-levels.enum';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	checkAutoLogInAuthPageRouter,
	shouldNavigateRestrictAuth,
} from '../../utils/authentication';
import { FACEBOOK_APP_ID } from '../../constants/facebook';
import { LogInFacebookUserDto } from '../../models/dtos/user/log-in-facebook-user.dto';
import { LogInGoogleUserDto } from '../../models/dtos/user/log-in-google-user.dto';
import { ResponseErrorHandler } from '../../models/dtos/response-error-handler';
import { HOME_ROUTE } from '../../constants/routes';
import { ThirdPartyLogInTypesEnum } from '../../models/enums/third-party-log-in-types.enum';
import { FormControl, CircularProgress, Typography, Box } from '@mui/material';

type CustomState = {
	email: string;
	password: string;
	// keepLogged: boolean;
	isMailLoading: boolean;
	isFacebookLoading: boolean;
	isGoogleLoading: boolean;
	errorMessage: string;
	displayErrorMessage: boolean;
	authChecked: boolean;
	authUser: UserEntity | undefined;
};

export default function LoginScreen(): ReactElement {
	const initialized = useRef(false);
	const location = useLocation();
	const navigate = useNavigate();
	const sessionCredentials = checkSessionLogIn();
	const isCookieCredentials = isUserCredentialsCookie();
	const [state, setState] = useReducer<
		Reducer<CustomState, Partial<CustomState>>
	>((oldState, newState) => ({ ...oldState, ...newState }), {
		email: '',
		password: '',
		// keepLogged: false,
		isMailLoading: false,
		isFacebookLoading: false,
		isGoogleLoading: false,
		errorMessage: '',
		displayErrorMessage: false,
		authChecked: sessionCredentials.data !== undefined || !isCookieCredentials,
		authUser: sessionCredentials.data,
	});

	const handleThirdPartyLogIn = (response: ResponseErrorHandler): void => {
		if (isRequestError(response)) {
			setState({
				isFacebookLoading: false,
				isGoogleLoading: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const data: UserEntity = response.data;
			// if (state.keepLogged) {
			storeUserCredentialsInCookie({
				thirdPartyAuthority: data.thirdParty?.authority,
				thirdPartyToken:
					data.thirdParty?.authority === ThirdPartyLogInTypesEnum.GOOGLE
						? data.thirdParty?.refreshToken
						: data.thirdParty?.token,
				id: data.id,
			});
			// }
			storeUserCredentialsSession(data);
			if (location.state?.from) {
				navigate(location.state.from);
			} else {
				navigate(HOME_ROUTE);
			}
		}
	};

	const handleLogInGoogle = async (
		codeResponse: Omit<
			CodeResponse,
			'error' | 'error_description' | 'error_uri'
		>
	): Promise<void> => {
		if (codeResponse) {
			const dto: LogInGoogleUserDto = {
				code: codeResponse.code,
			};
			const response = await logInGoogle(dto);
			handleThirdPartyLogIn(response);
		} else {
			setState({
				isGoogleLoading: false,
				displayErrorMessage: true,
				errorMessage: translations.error_occurred,
			});
		}
	};

	const openGoogleLoginFlow = useGoogleLogin({
		onSuccess: handleLogInGoogle,
		flow: 'auth-code',
		onNonOAuthError: () => {
			setState({
				isGoogleLoading: false,
			});
		},
	});

	const openGoogleLogIn = async (): Promise<void> => {
		setState({
			isGoogleLoading: true,
		});
		openGoogleLoginFlow();
	};

	const enableLoginButton = (): boolean => {
		return (
			isEmailValid(state.email) &&
			isPasswordValid(state.password) &&
			!state.isMailLoading
		);
	};

	const handleLogInCredentials = async (): Promise<void> => {
		setState({
			isMailLoading: true,
		});

		const dto: LogInUserDto = {
			password: state.password,
			mail: state.email,
		};
		const response = await logInCredentials(dto);
		if (isRequestError(response)) {
			setState({
				isMailLoading: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const data: UserEntity = response.data;
			// if (state.keepLogged) {
			storeUserCredentialsInCookie({
				password: data.password || '',
				id: data.id,
			});
			// }
			storeUserCredentialsSession(data);
			if (location.state?.from) {
				navigate(location.state.from);
			} else {
				navigate(HOME_ROUTE);
			}
		}
	};

	const handleLogInFacebook = async (
		facebookData: LoginResponse
	): Promise<void> => {
		if (facebookData.status === 'connected' && facebookData.authResponse) {
			const dto: LogInFacebookUserDto = {
				token: facebookData.authResponse.accessToken,
			};
			const response = await logInFacebook(dto, false);
			handleThirdPartyLogIn(response);
		} else {
			setState({
				isFacebookLoading: false,
				displayErrorMessage: true,
				errorMessage: translations.error_occurred,
			});
		}
	};

	const openFacebookLogIn = async (): Promise<void> => {
		setState({
			isFacebookLoading: true,
		});
		FacebookLoginClient.login(
			(res) => {
				handleLogInFacebook(res);
			},
			{
				scope: 'public_profile, email, user_gender, user_birthday',
			}
		);
	};

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;
			(async (): Promise<void> => {
				await FacebookLoginClient.loadSdk(translations.locale, false);
				FacebookLoginClient.init({
					appId: FACEBOOK_APP_ID,
					// @ts-ignore
					version: 'v15.0',
				});
				await checkAutoLogInAuthPageRouter(location, navigate, setState);
			})();
		}
	}, [location, navigate]);

	const shouldNavigateAuth = shouldNavigateRestrictAuth(state, location);
	if (shouldNavigateAuth !== undefined) {
		return shouldNavigateAuth;
	}

	if (!state.authChecked) {
		return (
			<Box className='page-fill flex-center'>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box className='page-fill background-gradient-accents'>
			<Box className='page-fill-middle-container background-main'>
				<FormControl fullWidth={true}>
					<Typography
						variant='h1'
						className='text-align-center color-accent'
						sx={{
							marginBottom: '40px',
						}}
					>
						{translations.log_in}
					</Typography>
					<ThemedTextField
						onChangeTextCallback={(text: string): void => {
							setState({
								email: text,
							});
						}}
						label={translations.e_mail}
						type='e-mail'
						value={state.email}
					/>
					<ThemedTextField
						onChangeTextCallback={(text: string): void => {
							setState({
								password: text,
							});
						}}
						label={translations.password}
						type='password'
						value={state.password}
					/>
					{/*<Box display='flex' justifyContent='center'>*/}
					{/*	<ThemedCheckBox*/}
					{/*		checked={state.keepLogged}*/}
					{/*		label={'Rester connecté'}*/}
					{/*		onChangeCallback={(value: boolean): void => {*/}
					{/*			setState({*/}
					{/*				keepLogged: value,*/}
					{/*			});*/}
					{/*		}}*/}
					{/*	/>*/}
					{/*</Box>*/}
					<ThemedIconTextButton
						isLoading={state.isMailLoading}
						enabled={enableLoginButton()}
						text={translations.log_in}
						materialIcon={MAIL_ICON}
						backgroundColor={ACCENT_PRIMARY_COLOR}
						onClickCallback={handleLogInCredentials}
						fullWidth={true}
						isSubmitButton={true}
					/>
					<Typography
						variant='body1'
						className='text-align-center color-classic-text'
						sx={{
							marginBottom: '10px',
							marginTop: '10px',
						}}
					>
						{translations.or}
					</Typography>
					<ThemedIconTextButton
						isLoading={state.isFacebookLoading}
						enabled={true}
						text={translations.facebook}
						materialIcon={FACEBOOK_ICON}
						backgroundColor={FACEBOOK_COLOR}
						onClickCallback={openFacebookLogIn}
						fullWidth={true}
					/>
					<ThemedIconTextButton
						isLoading={state.isGoogleLoading}
						enabled={true}
						text={translations.google}
						materialIcon={GOOGLE_ICON}
						backgroundColor={GOOGLE_COLOR}
						onClickCallback={openGoogleLogIn}
						fullWidth={true}
					/>
				</FormControl>
			</Box>
			<ThemedSnackbar
				origin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				label={state.errorMessage}
				level={SnackbarLevelsEnum.ERROR}
				onCloseCallback={(): void => {
					setState({
						displayErrorMessage: false,
					});
				}}
				open={state.displayErrorMessage}
			/>
		</Box>
	);
}
