import React, {
	ReactElement,
	Reducer,
	useReducer,
	useEffect,
	useRef,
} from 'react';
import moment from 'moment';
import { CircularProgress, Box, useTheme, Avatar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	checkSessionLogIn,
	isUserCredentialsCookie,
} from '../../services/login.service';
import { UserEntity } from '../../models/entities/user/user.entity';
import { checkAutoLogInProtectedPageRouter } from '../../utils/authentication';
import DrawerComponent from '../components/navigation/DrawerComponent';
import {
	deleteDanger,
	getDangers,
	closeDanger,
	getDangersExportCsv,
} from '../../services/danger.service';
import { isRequestError } from '../../utils/network';
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridRowSelectionModel,
	GridValueFormatterParams,
} from '@mui/x-data-grid';
import {
	getDateTimeFormatFromIso,
	translations,
} from '../../utils/translation';
import materialDataGridStyle from '../styles/material.dataGrid.style';
import materialDataGridMenuStyle from '../styles/material.dataGridMenu.style';
import ThemedIconTextButton from '../components/form/ThemedIconTextButton';
import {
	CLOSE_ICON,
	DELETE_ICON,
	DOWNLOAD_ICON,
	EYE_ICON,
} from '../../constants/material-icons';
import {
	ACCENT_PRIMARY_COLOR,
	ACCENT_SECONDARY_COLOR,
	DELETE_COLOR,
} from '../../constants/colors';
import ThemedAlertDialog from '../components/ThemedAlertDialog';
import ThemedSnackbar from '../components/form/ThemedSnackbar';
import { SnackbarLevelsEnum } from '../../models/enums/snackbar-levels.enum';
import cloneDeep from 'lodash.clonedeep';
import { DangerEntity } from '../../models/entities/danger/danger.entity';
import { DeleteDangerDto } from '../../models/dtos/danger/delete-danger.dto';
import { CloseDangerDto } from '../../models/dtos/danger/close-danger.dto';
import { PictureThumbnailWrapperEntity } from '../../models/entities/picture/picture-thumbnail-wrapper.entity';
import { arrayLengthComparator } from '../../utils/data-grid-comparators';
import ThemedAlertDialogShowJson from '../components/ThemedAlertDialogShowJson';
import ThemedAlertDialogExportDangers from '../components/ThemedAlertDialogExportDangers';
import { ExportDangersCsv } from '../../models/dtos/danger/export-dangers-csv.dto';
import fileDownload from 'js-file-download';

type CustomState = {
	errorMessage: string;
	displayErrorMessage: boolean;
	authChecked: boolean;
	authUser: UserEntity | undefined;
	dangersLoaded: boolean;
	dangers: DangerEntity[];
	selectedDanger?: DangerEntity;
	isCloseLoading: boolean;
	showDialogCloseDanger: boolean;
	isLoadingDialogCloseDanger: boolean;
	isDeleteLoading: boolean;
	showDialogDeleteDanger: boolean;
	isLoadingDialogDeleteDanger: boolean;
	dialogTitle?: string;
	showDialogInformationDanger: boolean;
	showDialogExportCsv: boolean;
	isLoadingDialogExportCsv: boolean;
};

export default function DangersScreen(): ReactElement {
	const initialized = useRef(false);
	const location = useLocation();
	const navigate = useNavigate();
	const sessionCredentials = checkSessionLogIn();
	const isCookieCredentials = isUserCredentialsCookie();
	const theme = useTheme();

	const [state, setState] = useReducer<
		Reducer<CustomState, Partial<CustomState>>
	>((oldState, newState) => ({ ...oldState, ...newState }), {
		errorMessage: '',
		authChecked: sessionCredentials.data !== undefined || !isCookieCredentials,
		authUser: sessionCredentials.data,
		dangers: [],
		displayErrorMessage: false,
		dangersLoaded: false,
		isCloseLoading: false,
		isDeleteLoading: false,
		showDialogCloseDanger: false,
		showDialogDeleteDanger: false,
		isLoadingDialogCloseDanger: false,
		isLoadingDialogDeleteDanger: false,
		showDialogInformationDanger: false,
		showDialogExportCsv: false,
		isLoadingDialogExportCsv: false,
	});
	const [rowSelectionModel, setRowSelectionModel] =
		React.useState<GridRowSelectionModel>([]);

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;
			(async (): Promise<void> => {
				const logInResponse = await checkAutoLogInProtectedPageRouter(
					location,
					navigate,
					setState
				);
				if (!logInResponse.shouldStay || !logInResponse.authUser) {
					return;
				}
				const dangersResponse = await getDangers(
					logInResponse.authUser.token || ''
				);
				if (isRequestError(dangersResponse)) {
					setState({
						errorMessage: dangersResponse.message || '',
						displayErrorMessage: true,
					});
				} else {
					setState({
						dangersLoaded: true,
						dangers: dangersResponse.data,
					});
				}
			})();
		}
	});

	const handleDeleteDanger = async (): Promise<void> => {
		const danger = state.selectedDanger;
		const authUser = state.authUser;
		if (!danger || !authUser) {
			return;
		}
		setState({
			isLoadingDialogDeleteDanger: true,
		});
		const dto: DeleteDangerDto = {
			dangerId: danger.id,
			locationHashKey: danger.locationHashKey,
		};
		const response = await deleteDanger(dto, authUser.token || '');
		if (isRequestError(response)) {
			setState({
				isLoadingDialogDeleteDanger: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const dangers = cloneDeep(state.dangers);
			const index = dangers.findIndex(
				(dangerUpdate: DangerEntity) => dangerUpdate.id === danger.id
			);
			if (index === -1) {
				return;
			}
			dangers.splice(index, 1);
			setState({
				isLoadingDialogDeleteDanger: false,
				isDeleteLoading: false,
				dangers: dangers,
				showDialogDeleteDanger: false,
			});
		}
	};

	const handleDeleteDangerDialog = async (): Promise<void> => {
		const danger = state.selectedDanger;
		const authUser = state.authUser;
		if (!danger || !authUser) {
			return;
		}
		setState({
			isDeleteLoading: true,
			dialogTitle: `${translations.delete} - ${danger.city}`,
			showDialogDeleteDanger: true,
		});
	};

	const handleCloseDangerDialog = async (): Promise<void> => {
		const danger = state.selectedDanger;
		const authUser = state.authUser;
		if (!danger || !authUser) {
			return;
		}
		setState({
			isCloseLoading: true,
			dialogTitle: `${translations.close} - ${danger.city}`,
			showDialogCloseDanger: true,
		});
	};

	const handleCloseDanger = async (): Promise<void> => {
		const danger = state.selectedDanger;
		const authUser = state.authUser;
		if (!danger || !authUser) {
			return;
		}
		setState({
			isLoadingDialogCloseDanger: true,
		});
		const dto: CloseDangerDto = {
			dangerId: danger.id,
			locationHashKey: danger.locationHashKey,
		};
		const response = await closeDanger(dto, authUser.token || '');
		if (isRequestError(response)) {
			setState({
				isLoadingDialogCloseDanger: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const dangers = cloneDeep(state.dangers);
			const index = dangers.findIndex(
				(dangerUpdate: DangerEntity) => dangerUpdate.id === danger.id
			);
			if (index === -1) {
				return;
			}
			danger.closed = true;
			dangers[index] = danger;
			setState({
				isLoadingDialogCloseDanger: false,
				isCloseLoading: false,
				dangers,
				showDialogCloseDanger: false,
			});
		}
	};

	const handleDisplayDangerDialog = async (): Promise<void> => {
		const danger = state.selectedDanger;
		if (!danger) {
			return;
		}
		setState({
			showDialogInformationDanger: true,
		});
	};

	const handleExportCsv = async (dto: ExportDangersCsv): Promise<void> => {
		const authUser = state.authUser;
		if (!authUser) {
			return;
		}
		setState({
			isLoadingDialogExportCsv: true,
		});
		const response = await getDangersExportCsv(dto, authUser.token || '');
		if (isRequestError(response)) {
			setState({
				isLoadingDialogExportCsv: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const csv = new Blob([response.data]);
			fileDownload(csv, 'export.csv');
			setState({
				isLoadingDialogExportCsv: false,
				showDialogExportCsv: false,
			});
		}
	};

	if (!state.authChecked) {
		return (
			<Box className='page-fill flex-center'>
				<CircularProgress />
			</Box>
		);
	}

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'Id',
			width: 350,
		},
		{
			field: 'pictures',
			headerName: translations.pictures,
			width: 160,
			renderCell: (params: GridRenderCellParams<any>): ReactElement => {
				const pictures: PictureThumbnailWrapperEntity[] = params.value;

				const component = pictures.map(
					(picture: PictureThumbnailWrapperEntity) => {
						return (
							<Avatar
								key={picture.id}
								alt={translations.picture}
								src={picture.picture.completePath}
							/>
						);
					}
				);

				return (
					<Box display='flex' justifyContent='center'>
						{component}
					</Box>
				);
			},
		},
		{
			field: 'city',
			headerName: translations.city,
			width: 200,
		},
		{
			field: 'comment',
			headerName: translations.description,
			width: 300,
		},
		{
			field: 'rideType',
			headerName: translations.ride,
			width: 130,
		},
		{
			field: 'roadType',
			headerName: translations.road,
			width: 130,
		},
		{
			field: 'dangerType',
			headerName: translations.type,
			width: 130,
		},
		{
			field: 'createdAt',
			headerName: translations.creation_date,
			width: 200,
			valueFormatter: (params: GridValueFormatterParams): string =>
				getDateTimeFormatFromIso(params.value),
			sortComparator: (v1, v2): number => {
				const v1Date = moment(v1).valueOf();
				const v2Date = moment(v2).valueOf();

				return v2Date - v1Date;
			},
		},
		{
			field: 'closed',
			headerName: translations.closed,
			width: 80,
			valueFormatter: (params: GridValueFormatterParams): string => {
				if (params.value === undefined || params.value !== true) {
					return translations.no;
				}
				return translations.yes;
			},
		},
		{
			field: 'comments',
			headerName: translations.comments,
			width: 150,
			valueFormatter: (params: GridValueFormatterParams): string => {
				return params.value.length;
			},
			sortComparator: arrayLengthComparator,
		},
		{
			field: 'satisfactions',
			headerName: translations.satisfactions,
			width: 150,
			valueFormatter: (params: GridValueFormatterParams): string => {
				return params.value.length;
			},
			sortComparator: arrayLengthComparator,
		},
		{
			field: 'dangerousness',
			headerName: translations.dangerousness_plural,
			width: 150,
			valueFormatter: (params: GridValueFormatterParams): string => {
				return params.value.length;
			},
			sortComparator: arrayLengthComparator,
		},
		{
			field: 'supports',
			headerName: translations.supports,
			width: 150,
			valueFormatter: (params: GridValueFormatterParams): string => {
				return params.value.length;
			},
			sortComparator: arrayLengthComparator,
		},
	];

	return (
		<DrawerComponent user={state.authUser}>
			<Box id='page-content'>
				<Box>
					<ThemedIconTextButton
						isLoading={state.isDeleteLoading}
						enabled={state.selectedDanger !== undefined}
						text={translations.delete}
						materialIcon={DELETE_ICON}
						backgroundColor={DELETE_COLOR}
						onClickCallback={handleDeleteDangerDialog}
						style={{
							marginRight: '5px',
						}}
					/>
					<ThemedIconTextButton
						isLoading={state.isCloseLoading}
						enabled={
							state.selectedDanger !== undefined && !state.selectedDanger.closed
						}
						text={translations.close}
						materialIcon={CLOSE_ICON}
						backgroundColor={ACCENT_SECONDARY_COLOR}
						onClickCallback={handleCloseDangerDialog}
						style={{
							marginRight: '5px',
						}}
					/>
					<ThemedIconTextButton
						enabled={state.selectedDanger !== undefined}
						text={translations.display_all_information}
						materialIcon={EYE_ICON}
						backgroundColor={ACCENT_PRIMARY_COLOR}
						onClickCallback={handleDisplayDangerDialog}
						style={{
							marginRight: '5px',
						}}
					/>
					<ThemedIconTextButton
						enabled={true}
						text={translations.export_csv}
						materialIcon={DOWNLOAD_ICON}
						backgroundColor={ACCENT_PRIMARY_COLOR}
						onClickCallback={(): void => {
							setState({
								showDialogExportCsv: true,
							});
						}}
					/>
				</Box>
				<DataGrid
					sx={materialDataGridStyle({
						theme,
					})}
					componentsProps={{
						basePopper: {
							sx: materialDataGridMenuStyle({
								theme,
							}),
						},
					}}
					rows={state.dangers}
					columns={columns}
					pageSizeOptions={[50]}
					pagination={true}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 50,
							},
						},
						sorting: {
							sortModel: [{ field: 'createdAt', sort: 'asc' }],
						},
					}}
					autoHeight
					paginationMode={'client'}
					rowSelection
					onRowSelectionModelChange={(newRowSelectionModel): void => {
						if (rowSelectionModel[0] === newRowSelectionModel[0]) {
							// if row already selected
							setRowSelectionModel([]);
							setState({
								selectedDanger: undefined,
							});
						} else {
							setRowSelectionModel(newRowSelectionModel);
							setState({
								selectedDanger: state.dangers.find(
									(danger: DangerEntity) =>
										danger.id === newRowSelectionModel[0]
								),
							});
						}
					}}
					rowSelectionModel={rowSelectionModel}
				/>
			</Box>
			<ThemedAlertDialog
				showDialog={state.showDialogCloseDanger}
				description={translations.close_description}
				yesText={translations.close}
				noText={translations.cancel}
				onAgree={handleCloseDanger}
				onCancel={(): void => {
					setState({
						showDialogCloseDanger: false,
						isCloseLoading: false,
					});
				}}
				title={state.dialogTitle}
				yesButtonLoading={state.isLoadingDialogCloseDanger}
				yesButtonIcon={CLOSE_ICON}
			/>
			<ThemedAlertDialog
				showDialog={state.showDialogDeleteDanger}
				description={translations.delete_danger_description}
				yesText={translations.delete}
				noText={translations.cancel}
				onAgree={handleDeleteDanger}
				onCancel={(): void => {
					setState({
						showDialogDeleteDanger: false,
						isDeleteLoading: false,
					});
				}}
				title={state.dialogTitle}
				yesButtonLoading={state.isLoadingDialogDeleteDanger}
				yesButtonIcon={DELETE_ICON}
			/>
			<ThemedAlertDialogShowJson
				json={state.selectedDanger || {}}
				showDialog={state.showDialogInformationDanger}
				onCancel={(): void => {
					setState({
						showDialogInformationDanger: false,
					});
				}}
			/>
			<ThemedAlertDialogExportDangers
				showDialog={state.showDialogExportCsv}
				onCancel={(): void => {
					setState({
						showDialogExportCsv: false,
						isLoadingDialogExportCsv: false,
					});
				}}
				onExport={handleExportCsv}
				yesButtonLoading={state.isLoadingDialogExportCsv}
			/>
			<ThemedSnackbar
				origin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				label={state.errorMessage}
				level={SnackbarLevelsEnum.ERROR}
				onCloseCallback={(): void => {
					setState({
						displayErrorMessage: false,
					});
				}}
				open={state.displayErrorMessage}
			/>
		</DrawerComponent>
	);
}
