import { CSSObject, Theme } from '@mui/material';
import { DRAWER_SIZE_OPEN } from '../../constants/layout';
import { getModeFromTheme } from '../../utils/theme';
import {
	ACCENT_PRIMARY_COLOR,
	ACCENT_SECONDARY_COLOR,
	Colors,
} from '../../constants/colors';

type CustomProps = {
	open: boolean;
	theme: Theme;
	isOverlay?: boolean;
};

const openedMixin = (props: CustomProps): CSSObject => ({
	width: DRAWER_SIZE_OPEN,
	transition: props.theme.transitions.create(['width', 'opacity'], {
		easing: props.theme.transitions.easing.sharp,
		duration: props.theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (props: CustomProps): CSSObject => ({
	transition: props.theme.transitions.create(['width', 'opacity'], {
		easing: props.theme.transitions.easing.sharp,
		duration: props.theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: props.isOverlay
		? DRAWER_SIZE_OPEN
		: `calc(${props.theme.spacing(7)} + 1px)`,
	...(!props.isOverlay && {
		[props.theme.breakpoints.up('sm')]: {
			width: `calc(${props.theme.spacing(8)} + 1px)`,
		},
	}),
});

const paper = (mode: string): CSSObject => {
	// @ts-ignore
	const colors = Colors[mode];

	return {
		background: `linear-gradient(${colors[ACCENT_PRIMARY_COLOR]}, ${colors[ACCENT_SECONDARY_COLOR]})`,
	};
};

export default function materialDrawerStyle(props: CustomProps): object {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	return {
		'&&': {
			flexShrink: 0,
			whiteSpace: 'nowrap',
			boxSizing: 'border-box',
			...(props.open && {
				...openedMixin(props),
				'& .MuiDrawer-paper': {
					...openedMixin(props),
					...paper(mode),
				},
			}),
			...(!props.open && {
				...closedMixin(props),
				'& .MuiDrawer-paper': {
					...closedMixin(props),
					...paper(mode),
				},
			}),
		},
	};
}
