import axios from 'axios';
import { ResponseDto } from '../models/dtos/response-dto';
import { ResponseErrorHandler } from '../models/dtos/response-error-handler';
import { translations } from './translation';
import { isInDevelopmentEnvironment } from './current-mode';

export const BASE_URL = isInDevelopmentEnvironment()
	? 'https://management.api.surico.fr'
	: 'https://management.api.surico.fr';

export const DANGERS_V1_ROUTE = '/api/services/dangers/v1';
export const USERS_V1_ROUTE = '/api/services/users/v1';

export const makePostRequest = async (
	url: string,
	body: any,
	token?: string
): Promise<ResponseDto | null> => {
	try {
		const { data } = await axios({
			method: 'post',
			url,
			timeout: 5000,
			maxContentLength: 10000000, // limit size to 10mb
			maxBodyLength: 10000000,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Accept-Language': translations.getLanguage(),
				...(token ? { Authorization: `Bearer ${token}` } : undefined),
			},
			data: body,
		});
		return data;
	} catch (e: any) {
		if (e.response?.data) {
			// if the server sent the error, axios receives it
			return e.response.data;
		}
		return null;
	}
};

export const makePutRequest = async (
	url: string,
	body: any,
	token?: string
): Promise<ResponseDto | null> => {
	try {
		const { data } = await axios({
			method: 'put',
			url,
			timeout: 5000,
			maxContentLength: 10000000, // limit size to 10mb
			maxBodyLength: 10000000,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Accept-Language': translations.getLanguage(),
				...(token ? { Authorization: `Bearer ${token}` } : undefined),
			},
			data: body,
		});
		return data;
	} catch (e: any) {
		if (e.response?.data) {
			// if the server sent the error, axios receives it
			return e.response.data;
		}
		return null;
	}
};

export const makeDeleteRequest = async (
	url: string,
	body: any,
	token?: string
): Promise<ResponseDto | null> => {
	try {
		const { data } = await axios({
			method: 'delete',
			url,
			timeout: 5000,
			maxContentLength: 10000000, // limit size to 10mb
			maxBodyLength: 10000000,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Accept-Language': translations.getLanguage(),
				...(token ? { Authorization: `Bearer ${token}` } : undefined),
			},
			data: body === undefined ? {} : body,
		});
		return data;
	} catch (e: any) {
		if (e.response?.data) {
			// if the server sent the error, axios receives it
			return e.response.data;
		}
		return null;
	}
};

export const makeGetRequest = async (
	url: string,
	token?: string
): Promise<ResponseDto | null> => {
	try {
		const { data } = await axios({
			method: 'get',
			url,
			timeout: 15000,
			maxContentLength: 10000000, // limit size to 10mb
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				'Accept-Language': translations.getLanguage(),
				...(token ? { Authorization: `Bearer ${token}` } : undefined),
			},
		});
		return data;
	} catch (e: any) {
		if (e.response?.data) {
			// if the server sent the error, axios receives it
			return e.response.data;
		}
		return null;
	}
};

export const handleRequestResult = (
	response: ResponseDto | null | undefined
): ResponseErrorHandler => {
	if (!response || (response.code === 'ERROR' && !response.message)) {
		return {
			message: translations.error_occurred,
			errorKey: response?.errorKey,
			isError: true,
		};
	}
	if (response.code === 'ERROR' && response.message) {
		return {
			message: response.message,
			isError: true,
			errorKey: response.errorKey,
		};
	}
	return {
		data: response.content,
		isError: false,
	};
};

export const isRequestError = (message: ResponseErrorHandler): boolean => {
	if (message.isError) {
		return true;
	}
	return false;
};

export const makeGetRequestFile = async (
	url: string,
	token?: string
): Promise<ResponseDto | null> => {
	try {
		const { data, status } = await axios({
			method: 'get',
			url,
			timeout: 15000,
			maxContentLength: 10000000, // limit size to 10mb
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'Cache-Control': 'no-cache',
				'Accept-Language': translations.getLanguage(),
				...(token ? { Authorization: `Bearer ${token}` } : undefined),
			},
		});
		return {
			code: 'OK',
			content: data,
			status,
		};
	} catch (e: any) {
		if (e.response?.data) {
			// if the server sent the error, axios receives it
			return e.response.data;
		}
		return null;
	}
};
