import { CSSObject, Theme } from '@mui/material';
import { Colors, WHITE } from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
	backgroundColor: string;
};

export default function materialButtonStyle(props: CustomProps): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'&&': {
			marginTop: '5px',
			marginBottom: '5px',
			color: colors[WHITE],
			justifyContent: 'start',
			backgroundColor: colors[props.backgroundColor],
		},
		'&&:disabled': {
			opacity: 0.6,
		},
	};
}
