import { CSSObject } from '@mui/material';

export default function materialDrawerIconStyle(): CSSObject {
	// @ts-ignore
	return {
		'&&': {
			fontSize: 30,
		},
	};
}
