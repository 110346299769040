import { BanUserDto } from '../models/dtos/user/ban-user.dto';
import { ResponseErrorHandler } from '../models/dtos/response-error-handler';
import {
	BASE_URL,
	handleRequestResult,
	makeDeleteRequest,
	makeGetRequest,
	makePutRequest,
	USERS_V1_ROUTE,
} from '../utils/network';
import { UnbanUserDto } from '../models/dtos/user/unban-user.dto';
import { DeleteUserDto } from '../models/dtos/user/delete-user.dto';

export const getUsers = async (
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makeGetRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/find/all`,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const banUser = async (
	dto: BanUserDto,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePutRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/profile/ban`,
			dto,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const unbanUser = async (
	dto: UnbanUserDto,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePutRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/profile/unban`,
			dto,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const deleteUser = async (
	dto: DeleteUserDto,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makeDeleteRequest(
			`${BASE_URL}${USERS_V1_ROUTE}/profile/delete`,
			dto,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};
