import { ResponseErrorHandler } from '../models/dtos/response-error-handler';
import {
	BASE_URL,
	handleRequestResult,
	makeDeleteRequest,
	makeGetRequest,
	makePutRequest,
	makeGetRequestFile,
	DANGERS_V1_ROUTE,
} from '../utils/network';
import { CloseDangerDto } from '../models/dtos/danger/close-danger.dto';
import { DeleteCommentDto } from '../models/dtos/danger/delete-comment.dto';
import { DeleteDangerDto } from '../models/dtos/danger/delete-danger.dto';
import { ExportDangersCsv } from '../models/dtos/danger/export-dangers-csv.dto';

export const getDangers = async (
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makeGetRequest(
			`${BASE_URL}${DANGERS_V1_ROUTE}/find/all`,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const getDangersExportCsv = async (
	dto: ExportDangersCsv,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		let url = `${BASE_URL}${DANGERS_V1_ROUTE}/extract/csv?postalCode=${dto.postalCode}&status=${dto.status}`;
		if (dto.startDate) {
			url = `${url}&startDate=${dto.startDate}`;
		}
		if (dto.endDate) {
			url = `${url}&endDate=${dto.endDate}`;
		}
		const response = await makeGetRequestFile(url, token);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const closeDanger = async (
	dto: CloseDangerDto,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makePutRequest(
			`${BASE_URL}${DANGERS_V1_ROUTE}/close`,
			dto,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const deleteDanger = async (
	dto: DeleteDangerDto,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makeDeleteRequest(
			`${BASE_URL}${DANGERS_V1_ROUTE}/delete`,
			dto,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};

export const deleteComment = async (
	dto: DeleteCommentDto,
	token: string
): Promise<ResponseErrorHandler> => {
	try {
		const response = await makeDeleteRequest(
			`${BASE_URL}${DANGERS_V1_ROUTE}/comment/delete`,
			dto,
			token
		);

		return handleRequestResult(response);
	} catch (e: any) {
		//crashlytics().recordError(e);
		return handleRequestResult(null);
	}
};
