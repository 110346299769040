import { PaletteOptions } from '@mui/material';
import { Colors } from '../../constants/colors';

export const palette: PaletteOptions = {
	primary: {
		main: Colors.light.accentPrimary,
	},
	error: {
		main: Colors.light.error,
	},
	success: {
		main: Colors.light.success,
	},
	info: {
		main: Colors.light.mainBackground,
	},
	text: {
		primary: Colors.light.classicText,
	},
	background: {
		default: Colors.light.mainBackground,
	},
};
