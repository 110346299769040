import * as EmailValidator from 'email-validator';
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../constants/profile';

export const isEmailValid = (
	email: string | undefined,
	mandatory: boolean = true
): boolean => {
	if (mandatory && !email) {
		return false;
	}
	return !email || EmailValidator.validate(email);
};

export const isPasswordValid = (
	password: string | undefined,
	mandatory: boolean = true
): boolean => {
	if (mandatory && !password) {
		return false;
	}
	return (
		!password ||
		(password.length >= PASSWORD_MIN_LENGTH &&
			password.length <= PASSWORD_MAX_LENGTH)
	);
};
