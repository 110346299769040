import React, { ReactElement } from 'react';
import { useRouteError, useLocation, Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { translations } from '../../utils/translation';
import { HOME_ROUTE } from '../../constants/routes';

export default function ErrorScreen(): ReactElement {
	const error: any = useRouteError();

	const location = useLocation();
	const is404 = error.status === 404;

	const errorMessage = is404
		? translations.page_not_found
		: translations.error_occurred;

	return (
		<Box className='page-fill background-gradient-accents'>
			<Box className='page-fill-middle-container background-main'>
				<Typography variant='h1' className='text-align-center'>
					{errorMessage}
				</Typography>
				<Box
					sx={{ marginTop: '20px' }}
					className='text-align-center color-accent'
				>
					<Link
						className='color-accent-a'
						to={
							is404 && location.state?.from ? location.state.from : HOME_ROUTE
						}
						state={{
							from: location.pathname,
						}}
					>
						{location.state?.from
							? translations.get_to_previous_page
							: translations.get_to_home}
					</Link>
				</Box>
			</Box>
		</Box>
	);
}
