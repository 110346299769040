import { CSSObject, Theme } from '@mui/material';
import {
	ACCENT_PRIMARY_COLOR,
	CLASSIC_TEXT_COLOR,
	Colors,
	WHITE,
} from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
};

export default function materialCheckBoxStyle(props: CustomProps): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'&&': {
			marginTop: '5px',
			marginBottom: '5px',
			color: colors[WHITE],
		},
		'&&:disabled': {
			opacity: 0.6,
		},
		'& .MuiFormControlLabel-label': {
			color: colors[CLASSIC_TEXT_COLOR],
		},
		'& .MuiSvgIcon-root': {
			color: colors[CLASSIC_TEXT_COLOR],
		},
		'&& .Mui-checked': {
			'& .MuiSvgIcon-root': {
				color: colors[ACCENT_PRIMARY_COLOR],
			},
		},
	};
}
