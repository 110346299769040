import { CSSObject, Theme } from '@mui/material';
import {
	ACCENT_PRIMARY_COLOR,
	CLASSIC_TEXT_COLOR,
	Colors,
	HINT_COLOR,
	PLACEHOLDER_COLOR,
} from '../../constants/colors';
import { getModeFromTheme } from '../../utils/theme';

type CustomProps = {
	theme: Theme;
};

export default function materialSelectStyle(props: CustomProps): CSSObject {
	const mode = getModeFromTheme(props.theme);
	// @ts-ignore
	const colors = Colors[mode];
	return {
		'&&': {
			marginTop: '5px',
			marginBottom: '5px',
		},
		'& fieldset': {
			borderColor: colors[HINT_COLOR],
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: colors[ACCENT_PRIMARY_COLOR],
		},
		'& .MuiSvgIcon-root': {
			color: colors[CLASSIC_TEXT_COLOR],
		},
		'& + label': {
			color: colors[PLACEHOLDER_COLOR],
		},
	};
}
