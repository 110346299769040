import { useTheme, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as MuiIcons from '@mui/icons-material';
import React, { ReactElement } from 'react';
import materialButtonStyle from '../../styles/material.button.style';

type CustomProps = {
	onClickCallback: any;
	backgroundColor: string;
	materialIcon: string;
	text: string;
	isLoading?: boolean;
	enabled: boolean;
	fullWidth?: boolean;
	style?: any;
	isSubmitButton?: boolean;
};

export default function ThemedIconTextButton(props: CustomProps): ReactElement {
	const theme = useTheme();
	// @ts-ignore
	const Icon: any = MuiIcons[props.materialIcon];

	return (
		<LoadingButton
			sx={materialButtonStyle({
				theme,
				backgroundColor: props.backgroundColor,
			})}
			style={props.style}
			loading={props.isLoading}
			disabled={!props.enabled}
			loadingPosition='start'
			startIcon={<Icon />}
			variant='contained'
			fullWidth={props.fullWidth}
			size='large'
			onClick={props.onClickCallback}
			type={props.isSubmitButton ? 'submit' : undefined}
		>
			<Typography variant='button'>{props.text}</Typography>
		</LoadingButton>
	);
}
