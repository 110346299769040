export const MAIN_BACKGROUND_COLOR = 'mainBackground';
export const HINT_COLOR = 'hintColor';
export const RIPPLE_COLOR = 'rippleColor';
export const ICON_GREY_COLOR = 'iconGrey';
export const ACCENT_PRIMARY_COLOR = 'accentPrimary';
export const ACCENT_SECONDARY_COLOR = 'accentSecondary';
export const CLASSIC_TEXT_COLOR = 'classicText';
export const FACEBOOK_COLOR = 'facebookColor';
export const GOOGLE_COLOR = 'googleColor';
export const PLACEHOLDER_COLOR = 'formInputPlaceholder';
export const WHITE = 'white';
export const ALERT_TEXT_COLOR = 'alertTextColor';
export const DELETE_COLOR = 'error';
export const BAN_COLOR = 'error';
export const SUCCESS_COLOR = 'success';

export const Colors = {
	light: {
		accentPrimary: '#ff7602',
		accentSecondary: '#6d05ce',
		hintColor: 'rgba(0,0,0,0.3)',
		rippleColor: 'rgba(0,0,0,0.05)',
		iconGrey: '#a3a3a3',
		mainBackground: '#fff',
		classicText: '#8c8c8c',
		facebookColor: '#1877f2',
		googleColor: '#4285f4',
		formInputBackground: '#f4f4f4',
		formInputPlaceholder: '#cecece',
		white: '#fff',
		alertTextColor: 'rgba(0,0,0,0.7)',
		error: '#d32f2f',
		success: '#43A047',
	},
	dark: {
		accentPrimary: '#d57527',
		accentSecondary: '#6d05ce',
		hintColor: 'rgba(255,255,255,0.15)',
		rippleColor: 'rgba(255,255,255,0.08)',
		iconGrey: '#868686',
		classicText: '#989898',
		mainBackground: '#1e1e1e',
		facebookColor: '#1877f2',
		googleColor: '#4285f4',
		formInputBackground: '#2f2f2f',
		formInputPlaceholder: '#6f6f6f',
		white: '#fff',
		alertTextColor: 'rgba(255,255,255,0.7)',
		error: '#d32f2f',
		success: '#43A047',
	},
};
