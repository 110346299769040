import React, { ReactElement, useMemo } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
	createTheme,
	ThemeProvider,
	CssBaseline,
	useMediaQuery,
} from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
	DANGERS_ROUTE,
	HOME_ROUTE,
	LOGIN_ROUTE,
	USERS_ROUTE,
} from './constants/routes';
import { palette as paletteLight } from './view/styles/material.palette.light';
import { palette as paletteDark } from './view/styles/material.palette.dark';
import LoginScreen from './view/screens/login.screen';
import RootScreen from './view/screens/root.screen';
import ErrorScreen from './view/screens/error.screen';
import UsersScreen from './view/screens/users.screen';
import DangersScreen from './view/screens/dangers.screen';

export default function App(): ReactElement {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	document.documentElement.setAttribute(
		'data-theme',
		prefersDarkMode ? 'dark' : 'light'
	);

	const router = createBrowserRouter([
		{
			path: HOME_ROUTE,
			element: <RootScreen />,
			errorElement: <ErrorScreen />,
		},
		{
			path: USERS_ROUTE,
			element: <UsersScreen />,
		},
		{
			path: LOGIN_ROUTE,
			element: <LoginScreen />,
		},
		{
			path: DANGERS_ROUTE,
			element: <DangersScreen />,
		},
	]);

	const theme = useMemo(
		() =>
			createTheme({
				components: {
					MuiButtonBase: {
						defaultProps: {
							TouchRippleProps: {
								style: {
									color: 'rgba(255,255,255,0.6)',
								},
							},
						},
					},
				},
				palette: {
					mode: prefersDarkMode ? 'dark' : 'light',
					...(prefersDarkMode ? paletteDark : paletteLight),
				},
				typography: {
					fontFamily: ['Roboto', 'Arial', 'sans-serif', 'serif'].join(','),
					h1: {
						fontSize: '30pt',
						fontWeight: 'bold',
					},
					body1: {
						fontSize: '15pt',
					},
					button: {
						fontSize: '15pt',
					},
					body2: {
						fontSize: '13pt',
					},
				},
			}),
		[prefersDarkMode]
	);

	return (
		<GoogleOAuthProvider
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
		>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<RouterProvider router={router} />
			</ThemeProvider>
		</GoogleOAuthProvider>
	);
}
