import React, {
	ReactElement,
	Reducer,
	useReducer,
	useEffect,
	useRef,
} from 'react';
import moment from 'moment';
import { Box, CircularProgress, useTheme, Avatar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	checkSessionLogIn,
	isUserCredentialsCookie,
} from '../../services/login.service';
import { UserEntity } from '../../models/entities/user/user.entity';
import { checkAutoLogInProtectedPageRouter } from '../../utils/authentication';
import DrawerComponent from '../components/navigation/DrawerComponent';
import {
	banUser,
	deleteUser,
	getUsers,
	unbanUser,
} from '../../services/user.service';
import { isRequestError } from '../../utils/network';
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridRowSelectionModel,
	GridValueFormatterParams,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import {
	getDateTimeFormatFromIso,
	translations,
} from '../../utils/translation';
import materialDataGridStyle from '../styles/material.dataGrid.style';
import materialDataGridMenuStyle from '../styles/material.dataGridMenu.style';
import ThemedIconTextButton from '../components/form/ThemedIconTextButton';
import {
	BAN_ICON,
	DELETE_ICON,
	UNBAN_ICON,
	EYE_ICON,
} from '../../constants/material-icons';
import {
	ACCENT_PRIMARY_COLOR,
	BAN_COLOR,
	DELETE_COLOR,
} from '../../constants/colors';
import { BanUserDto } from '../../models/dtos/user/ban-user.dto';
import { DeleteUserDto } from '../../models/dtos/user/delete-user.dto';
import ThemedAlertDialogCheckbox from '../components/ThemedAlertDialogCheckbox';
import ThemedAlertDialog from '../components/ThemedAlertDialog';
import ThemedSnackbar from '../components/form/ThemedSnackbar';
import { SnackbarLevelsEnum } from '../../models/enums/snackbar-levels.enum';
import cloneDeep from 'lodash.clonedeep';
import { UnbanUserDto } from '../../models/dtos/user/unban-user.dto';
import { arrayLengthComparator } from '../../utils/data-grid-comparators';
import ThemedAlertDialogShowJson from '../components/ThemedAlertDialogShowJson';

type CustomState = {
	errorMessage: string;
	displayErrorMessage: boolean;
	authChecked: boolean;
	authUser: UserEntity | undefined;
	usersLoaded: boolean;
	users: UserEntity[];
	selectedUser?: UserEntity;
	isBanLoading: boolean;
	isUnbanLoading: boolean;
	isDeleteLoading: boolean;
	showDialogBanUser: boolean;
	isLoadingDialogBanUser: boolean;
	showDialogUnbanUser: boolean;
	isLoadingDialogUnbanUser: boolean;
	showDialogDeleteUser: boolean;
	isLoadingDialogDeleteUser: boolean;
	dialogTitle?: string;
	showDialogInformationUser: boolean;
};

export default function UsersScreen(): ReactElement {
	const initialized = useRef(false);
	const location = useLocation();
	const navigate = useNavigate();
	const sessionCredentials = checkSessionLogIn();
	const isCookieCredentials = isUserCredentialsCookie();
	const theme = useTheme();

	const [state, setState] = useReducer<
		Reducer<CustomState, Partial<CustomState>>
	>((oldState, newState) => ({ ...oldState, ...newState }), {
		errorMessage: '',
		authChecked: sessionCredentials.data !== undefined || !isCookieCredentials,
		authUser: sessionCredentials.data,
		users: [],
		displayErrorMessage: false,
		usersLoaded: false,
		isBanLoading: false,
		isUnbanLoading: false,
		isDeleteLoading: false,
		showDialogBanUser: false,
		showDialogUnbanUser: false,
		showDialogDeleteUser: false,
		isLoadingDialogBanUser: false,
		isLoadingDialogUnbanUser: false,
		isLoadingDialogDeleteUser: false,
		showDialogInformationUser: false,
	});
	const [rowSelectionModel, setRowSelectionModel] =
		React.useState<GridRowSelectionModel>([]);

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;
			(async (): Promise<void> => {
				const logInResponse = await checkAutoLogInProtectedPageRouter(
					location,
					navigate,
					setState
				);
				if (!logInResponse.shouldStay || !logInResponse.authUser) {
					return;
				}
				const usersResponse = await getUsers(
					logInResponse.authUser.token || ''
				);
				if (isRequestError(usersResponse)) {
					setState({
						errorMessage: usersResponse.message || '',
						displayErrorMessage: true,
					});
				} else {
					setState({
						usersLoaded: true,
						users: usersResponse.data,
					});
				}
			})();
		}
	});

	const handleDeleteUser = async (deleteDangers: boolean): Promise<void> => {
		const user = state.selectedUser;
		const authUser = state.authUser;
		if (!user || !authUser) {
			return;
		}
		setState({
			isLoadingDialogDeleteUser: true,
		});
		const dto: DeleteUserDto = {
			deleteDangers: deleteDangers,
			userId: user.id,
		};
		const response = await deleteUser(dto, authUser.token || '');
		if (isRequestError(response)) {
			setState({
				isLoadingDialogDeleteUser: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const users = cloneDeep(state.users);
			const index = users.findIndex(
				(userUpdate: UserEntity) => userUpdate.id === user.id
			);
			if (index === -1) {
				return;
			}
			users.splice(index, 1);
			setState({
				isLoadingDialogDeleteUser: false,
				isDeleteLoading: false,
				users,
				showDialogDeleteUser: false,
			});
		}
	};

	const handleDeleteUserDialog = async (): Promise<void> => {
		const user = state.selectedUser;
		const authUser = state.authUser;
		if (!user || !authUser) {
			return;
		}
		setState({
			isDeleteLoading: true,
			dialogTitle: `${translations.delete} - ${user.mail}`,
			showDialogDeleteUser: true,
		});
	};

	const handleBanUserDialog = async (): Promise<void> => {
		const user = state.selectedUser;
		const authUser = state.authUser;
		if (!user || !authUser) {
			return;
		}
		setState({
			isBanLoading: true,
			dialogTitle: `${translations.ban} - ${user.mail}`,
			showDialogBanUser: true,
		});
	};

	const handleBanUser = async (deleteDangers: boolean): Promise<void> => {
		const user = state.selectedUser;
		const authUser = state.authUser;
		if (!user || !authUser) {
			return;
		}
		setState({
			isLoadingDialogBanUser: true,
		});
		const dto: BanUserDto = {
			deleteDangers: deleteDangers,
			userId: user.id,
		};
		const response = await banUser(dto, authUser.token || '');
		if (isRequestError(response)) {
			setState({
				isLoadingDialogBanUser: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const users = cloneDeep(state.users);
			const index = users.findIndex(
				(userUpdate: UserEntity) => userUpdate.id === user.id
			);
			if (index === -1) {
				return;
			}
			user.isBanned = true;
			if (deleteDangers) {
				user.createdDangers = [];
				user.firstname = undefined;
				user.picture = undefined;
			}
			users[index] = user;
			setState({
				isLoadingDialogBanUser: false,
				isBanLoading: false,
				users,
				showDialogBanUser: false,
			});
		}
	};

	const handleUnbanUserDialog = async (): Promise<void> => {
		const user = state.selectedUser;
		const authUser = state.authUser;
		if (!user || !authUser) {
			return;
		}
		setState({
			isUnbanLoading: true,
			dialogTitle: `${translations.unban} - ${user.mail}`,
			showDialogUnbanUser: true,
		});
	};

	const handleUnbanUser = async (): Promise<void> => {
		const user = state.selectedUser;
		const authUser = state.authUser;
		if (!user || !authUser) {
			return;
		}
		setState({
			isLoadingDialogUnbanUser: true,
		});
		const dto: UnbanUserDto = {
			userId: user.id,
		};
		const response = await unbanUser(dto, authUser.token || '');
		if (isRequestError(response)) {
			setState({
				isLoadingDialogUnbanUser: false,
				errorMessage: response.message || '',
				displayErrorMessage: true,
			});
		} else {
			const users = cloneDeep(state.users);
			const index = users.findIndex(
				(userUpdate: UserEntity) => userUpdate.id === user.id
			);
			if (index === -1) {
				return;
			}
			user.isBanned = false;
			users[index] = user;
			setState({
				isLoadingDialogUnbanUser: false,
				isUnbanLoading: false,
				users,
				showDialogUnbanUser: false,
			});
		}
	};

	const handleDisplayUserDialog = async (): Promise<void> => {
		const user = state.selectedUser;
		if (!user) {
			return;
		}
		setState({
			showDialogInformationUser: true,
		});
	};

	if (!state.authChecked) {
		return (
			<Box className='page-fill flex-center'>
				<CircularProgress />
			</Box>
		);
	}

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'Id',
			width: 350,
		},
		{
			field: 'picture',
			headerName: translations.picture,
			width: 80,
			valueGetter: (params: GridValueGetterParams): string => {
				if (params.row.thirdParty) {
					return params.row.thirdParty.picture;
				}
				return params.row.picture?.picture.completePath;
			},
			renderCell: (params: GridRenderCellParams<any>): ReactElement => {
				return <Avatar alt={translations.picture} src={params.value} />;
			},
		},
		{
			field: 'firstname',
			headerName: translations.firstname,
			width: 100,
		},
		{
			field: 'mail',
			headerName: translations.e_mail,
			width: 250,
		},
		{
			field: 'sign_up',
			headerName: translations.sign_up_method,
			width: 110,
			valueGetter: (params: GridValueGetterParams): string => {
				const capitalizeFirstLetter = (dataToConvert: string): string => {
					const dataParsed = dataToConvert.toLowerCase();
					return dataParsed.charAt(0).toUpperCase() + dataParsed.slice(1);
				};

				if (params.row.thirdParty) {
					return capitalizeFirstLetter(params.row.thirdParty.authority);
				}

				return 'Email';
			},
		},
		{
			field: 'createdAt',
			headerName: translations.sign_up_date,
			width: 200,
			valueFormatter: (params: GridValueFormatterParams): string =>
				getDateTimeFormatFromIso(params.value),
			sortComparator: (v1, v2): number => {
				const v1Date = moment(v1).valueOf();
				const v2Date = moment(v2).valueOf();

				return v2Date - v1Date;
			},
		},
		{
			field: 'premium',
			headerName: translations.premium,
			width: 100,
			valueFormatter: (params: GridValueFormatterParams): string => {
				if (params.value === undefined || params.value.isPremium !== true) {
					return translations.no;
				}
				return translations.yes;
			},
		},
		{
			field: 'isBanned',
			headerName: translations.banned,
			width: 80,
			valueFormatter: (params: GridValueFormatterParams): string => {
				if (params.value === undefined || params.value !== true) {
					return translations.no;
				}
				return translations.yes;
			},
		},
		{
			field: 'isAdmin',
			headerName: translations.admin,
			width: 80,
			valueFormatter: (params: GridValueFormatterParams): string => {
				if (params.value === undefined || params.value !== true) {
					return translations.no;
				}
				return translations.yes;
			},
		},
		{
			field: 'createdDangers',
			headerName: translations.created_dangers,
			width: 180,
			valueFormatter: (params: GridValueFormatterParams): string => {
				return params.value.length;
			},
			sortComparator: arrayLengthComparator,
		},
		{
			field: 'supportedDangers',
			headerName: translations.supported_dangers,
			width: 180,
			valueFormatter: (params: GridValueFormatterParams): string => {
				return params.value.length;
			},
			sortComparator: arrayLengthComparator,
		},
	];

	return (
		<DrawerComponent user={state.authUser}>
			<Box id='page-content'>
				<Box>
					<ThemedIconTextButton
						isLoading={state.isDeleteLoading}
						enabled={state.selectedUser !== undefined}
						text={translations.delete}
						materialIcon={DELETE_ICON}
						backgroundColor={DELETE_COLOR}
						onClickCallback={handleDeleteUserDialog}
						style={{
							marginRight: '5px',
						}}
					/>
					<ThemedIconTextButton
						isLoading={state.isBanLoading}
						enabled={
							state.selectedUser !== undefined && !state.selectedUser.isBanned
						}
						text={translations.ban}
						materialIcon={BAN_ICON}
						backgroundColor={BAN_COLOR}
						onClickCallback={handleBanUserDialog}
						style={{
							marginRight: '5px',
						}}
					/>
					<ThemedIconTextButton
						isLoading={state.isUnbanLoading}
						enabled={
							state.selectedUser !== undefined &&
							state.selectedUser.isBanned === true
						}
						text={translations.unban}
						materialIcon={UNBAN_ICON}
						backgroundColor={ACCENT_PRIMARY_COLOR}
						onClickCallback={handleUnbanUserDialog}
						style={{
							marginRight: '5px',
						}}
					/>
					<ThemedIconTextButton
						enabled={state.selectedUser !== undefined}
						text={translations.display_all_information}
						materialIcon={EYE_ICON}
						backgroundColor={ACCENT_PRIMARY_COLOR}
						onClickCallback={handleDisplayUserDialog}
					/>
				</Box>
				<DataGrid
					sx={materialDataGridStyle({
						theme,
					})}
					componentsProps={{
						basePopper: {
							sx: materialDataGridMenuStyle({
								theme,
							}),
						},
					}}
					rows={state.users}
					columns={columns}
					pageSizeOptions={[50]}
					pagination={true}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 50,
							},
						},
						sorting: {
							sortModel: [{ field: 'createdAt', sort: 'asc' }],
						},
					}}
					autoHeight
					paginationMode={'client'}
					rowSelection
					onRowSelectionModelChange={(newRowSelectionModel): void => {
						if (rowSelectionModel[0] === newRowSelectionModel[0]) {
							// if row already selected
							setRowSelectionModel([]);
							setState({
								selectedUser: undefined,
							});
						} else {
							setRowSelectionModel(newRowSelectionModel);
							setState({
								selectedUser: state.users.find(
									(user: UserEntity) => user.id === newRowSelectionModel[0]
								),
							});
						}
					}}
					rowSelectionModel={rowSelectionModel}
				/>
			</Box>
			<ThemedAlertDialogCheckbox
				showDialog={state.showDialogBanUser}
				checkBoxDescription={translations.delete_his_dangers}
				description={translations.ban_description}
				yesText={translations.ban}
				noText={translations.cancel}
				onAgree={handleBanUser}
				onCancel={(): void => {
					setState({
						showDialogBanUser: false,
						isBanLoading: false,
					});
				}}
				title={state.dialogTitle}
				yesButtonLoading={state.isLoadingDialogBanUser}
				yesButtonIcon={BAN_ICON}
			/>
			<ThemedAlertDialog
				showDialog={state.showDialogUnbanUser}
				description={translations.unban_description}
				yesText={translations.unban}
				noText={translations.cancel}
				onAgree={handleUnbanUser}
				onCancel={(): void => {
					setState({
						showDialogUnbanUser: false,
						isUnbanLoading: false,
					});
				}}
				title={state.dialogTitle}
				yesButtonLoading={state.isLoadingDialogUnbanUser}
				yesButtonIcon={UNBAN_ICON}
			/>
			<ThemedAlertDialogCheckbox
				showDialog={state.showDialogDeleteUser}
				checkBoxDescription={translations.delete_his_dangers}
				description={translations.delete_user_description}
				yesText={translations.delete}
				noText={translations.cancel}
				onAgree={handleDeleteUser}
				onCancel={(): void => {
					setState({
						showDialogDeleteUser: false,
						isDeleteLoading: false,
					});
				}}
				title={state.dialogTitle}
				yesButtonLoading={state.isLoadingDialogDeleteUser}
				yesButtonIcon={DELETE_ICON}
			/>
			<ThemedAlertDialogShowJson
				json={state.selectedUser || {}}
				showDialog={state.showDialogInformationUser}
				onCancel={(): void => {
					setState({
						showDialogInformationUser: false,
					});
				}}
			/>
			<ThemedSnackbar
				origin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				label={state.errorMessage}
				level={SnackbarLevelsEnum.ERROR}
				onCloseCallback={(): void => {
					setState({
						displayErrorMessage: false,
					});
				}}
				open={state.displayErrorMessage}
			/>
		</DrawerComponent>
	);
}
