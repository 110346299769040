import {
	useTheme,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import materialDialogStyle from '../styles/material.dialog.style';
import * as MuiIcons from '@mui/icons-material';
import materialButtonStyle from '../styles/material.button.style';
import { ACCENT_PRIMARY_COLOR } from '../../constants/colors';

type CustomProps = {
	showDialog: boolean;
	onCancel: any;
	onAgree: any;
	title?: string;
	description: string;
	noText: string;
	yesText: string;
	yesButtonIcon: string;
	yesButtonLoading: boolean;
};

export default function ThemedAlertDialog(props: CustomProps): ReactElement {
	const theme = useTheme();

	// @ts-ignore
	const Icon: any = MuiIcons[props.yesButtonIcon];

	return (
		<Dialog
			sx={materialDialogStyle({
				theme,
			})}
			open={props.showDialog}
			onClose={props.onCancel}
		>
			<DialogTitle className='color-accent'>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button disabled={props.yesButtonLoading} onClick={props.onCancel}>
					{props.noText}
				</Button>
				<LoadingButton
					sx={materialButtonStyle({
						theme,
						backgroundColor: ACCENT_PRIMARY_COLOR,
					})}
					onClick={props.onAgree}
					loadingPosition='start'
					variant='contained'
					startIcon={<Icon />}
					loading={props.yesButtonLoading}
				>
					<Typography variant='button'>{props.yesText}</Typography>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
