import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ReactElement } from 'react';
import { useTheme } from '@mui/material';
import materialDatePickerStyle from '../../styles/material.datePicker.style';
import materialDatePickerPopperStyle from '../../styles/material.datePickerPopper.style';
import dayjs, { Dayjs } from 'dayjs';
import DayjsUtc from 'dayjs/plugin/utc';
import { DatePickerTimeSetEnum } from '../../../models/enums/date-picker-time-set.enum';

dayjs.extend(DayjsUtc);

type CustomProps = {
	value?: Dayjs;
	label: string;
	required?: boolean;
	onChangeCallback: any;
	time?: DatePickerTimeSetEnum;
};

export default function ThemedDatePicker(props: CustomProps): ReactElement {
	const theme = useTheme();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				slotProps={{
					popper: {
						sx: materialDatePickerPopperStyle({ theme }),
					},
				}}
				sx={materialDatePickerStyle({ theme })}
				label={props.label}
				defaultValue={props.value}
				onAccept={(value: any): void => {
					props.onChangeCallback(value);
					if (props.time === undefined) {
						props.onChangeCallback(value.utc());
					} else if (props.time === DatePickerTimeSetEnum.START_DAY) {
						props.onChangeCallback(
							value.utc().set('hour', 0).set('minute', 0).set('second', 0)
						);
					} else {
						props.onChangeCallback(
							value.utc().set('hour', 23).set('minute', 59).set('second', 59)
						);
					}
				}}
			/>
		</LocalizationProvider>
	);
}
