import { Visibility, VisibilityOff } from '@mui/icons-material';
import materialTextFieldStyle from '../../styles/material.textField.style';
import React, { ReactElement, useState, MouseEvent, ChangeEvent } from 'react';
import {
	Box,
	useTheme,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
	TextField,
} from '@mui/material';

type CustomProps = {
	label: string;
	required?: boolean;
	type: string;
	onChangeTextCallback: any;
	value: string;
};

export default function ThemedTextField(props: CustomProps): ReactElement {
	const theme = useTheme();

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = (): void => setShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: MouseEvent<HTMLButtonElement>
	): void => {
		event.preventDefault();
	};

	return props.type === 'password' ? (
		<Box
			style={{ position: 'relative' }}
			sx={materialTextFieldStyle({ theme })}
		>
			<InputLabel htmlFor='outlined-adornment-password'>
				{props.label}
			</InputLabel>
			<OutlinedInput
				onChange={(event: ChangeEvent<HTMLInputElement>): void => {
					props.onChangeTextCallback(event.target.value);
				}}
				id='outlined-adornment-password'
				type={showPassword ? 'text' : 'password'}
				endAdornment={
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge='end'
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				label={props.label}
				fullWidth
				value={props.value}
			/>
		</Box>
	) : (
		<TextField
			sx={materialTextFieldStyle({ theme })}
			id='outlined-basic'
			variant='outlined'
			label={props.label}
			placeholder={props.label}
			required={props.required}
			fullWidth
			type={props.type}
			onChange={(event: ChangeEvent<HTMLInputElement>): void => {
				props.onChangeTextCallback(event.target.value);
			}}
			value={props.value}
		/>
	);
}
